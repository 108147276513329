* {
  margin: 0;
  padding: 0;
}

.banner-wrapper {
  width: 100%;
  padding: 130px 0 130px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .pick-your-team {
    text-align: center;
    margin-bottom: 60px;
    img {
      width: 100%;
    }
  }
  .banner-img-block {
    img {
      width: 100%;
    }
    display: flex;
    margin-top: 10px;
    align-items: center;
    gap: 18px;
    .img-grp {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
    .img-grp-2 {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }
    .banner-img {
      max-width: 100%;
      margin: auto;
    }
  }
}

.header {
  position: relative;
}

.header-section {
  position: fixed;
  left: 0;
  width: 100%;
  top: 18px;
  border-radius: 50px;
  .searchBar {
    padding: 18px;
    color: white;
    padding-left: 45px;
    padding-right: 45px;
    border-radius: 50px;
    background-color: #252323;
    .logo-dummy {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      position: relative;
    }
    .game2 {
      color: #ffffff;
      text-align: left;
      position: relative;
      font: 700 24px "Passion One", sans-serif;
    }
    .team2 {
      color: #ffffff;
      text-align: left;
      position: relative;
      font: 400 24px "Passion One", sans-serif;
    }
    
    .nav-menu-btn {
      border: none;
      border-radius: 360px;
      background: #aee67f;
      padding: 10px 20px 10px 20px;
      cursor: pointer;
    }
    .nav-menu-btn:hover {
      background-color: #0056b3;
    }
  }
}

.activity-page-one {
  display: flex;
  max-width: 100%;
  justify-content: center;
  .left-block {
    .activity-near-you {
      display: flex;
      .activity-near-you-img {
        margin: auto;
      }
    }
    .discription-block {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      div {
        width: 370px;
        margin: auto;
        margin-top: 15px;
      }
    }
  }
  .right-block {
    display: flex;
    justify-content: center;
    img {
      width: 100%;
    }
  }
}

.activity-page-two {
  .left-block {
    display: flex;
    justify-content: center;
    .img-container {
      background-image: url("./img/Polygon\ 2.svg");
      background-size: cover;
      background-repeat: no-repeat;
      width: 35rem;
      height: 35rem;
      .screenTwo {
        width: 25rem;
        padding-top: 10rem;
      }
    }
    .screenTwo-phone-size {
      display: none;
    }
    .screenTwo {
      padding: 5rem;
      width: 25rem;
    }
  }
  .right-block {
    display: flex;
    justify-content: center;
    padding-top: 42px;
    align-items: start;
    flex-direction: column;
    img {
      width: 100%;
    }
    .discription-block {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      div {
        width: 370px;
        margin: auto;
        margin-top: 15px;
      }
    }
  }
}

.divider {
  margin-top: 95px;
}

.activity-page-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .content-wrapper {
    flex-direction: column;
    display: flex;
    align-items: center;
    .Privacy-Policy {
      display: inline-block !important;
    }
    .Game-Team {
      font-weight: 600;
    }
    .terms-section {
      margin-top: 15px;
    }
  }
  .content-block-3 {
    display: flex;
    justify-content: center;
    .content-section {
      gap: 18px;
      display: flex;
      padding: 45px 0 45px 0;
      width: 1255px;
      flex-wrap: wrap;
      margin-top: 45px;
      align-items: center;
      border-radius: 23px;
      align-content: center;
      justify-content: center;
      background-color: rgba(241, 251, 233, 1);

      .DownloadText-img {
        height: 8rem;
      }
      .os-img {
        display: flex;
        gap: 12px;
        img {
          height: 23rem;
        }
      }
    }
  }
}

.activity-page-5 {
  padding: 90px 0 150px 0;
  .img-block {
    max-width: 480px;
    img {
      margin-bottom: 35px;
    }
    span {
      display: block;
      padding: 12px;
    }
  }
  .discription-block {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    div {
      width: 370px;
      margin: auto;
      margin-top: 15px;
    }
  }
  .mob-screen-layout {
    .mob-screen-block {
      max-width: 270px;
      img {
        width: 100%;
      }
    }
  }
}

.footer {
  width: 100%;
  padding: 50px;
  height: 246px;
  max-width: 1150px;
  border-radius: 25px;
  background-color: #252323;
  .footer-section {
    gap: 12px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;

    .footer-block {
      display: flex;
      align-items: center;
      gap: 8px;
      .logo {
        width: 42px;
        height: 42px;
        flex-shrink: 0;

        position: relative;
      }
      .game2 {
        color: #ffffff;
        text-align: left;
        position: relative;
        font: 700 24px "Passion One", sans-serif;
      }
      .team2 {
        color: #ffffff;
        text-align: left;
        position: relative;
        font: 400 24px "Passion One", sans-serif;
      }
    }
    .footer-block-2 {
      gap: 8px;
      display: flex;
      align-items: center;
    }
  }
  .footer-section-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    flex-wrap: wrap;

    .section-inner-block {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

@media only screen and (max-width: 735px) {
  .footer {
    .footer-section {
      justify-content: center !important;
    }
  }
}

@media only screen and (max-width: 480px) {
  .activity-page-one-grid {
    width: 480px !important;
  }
  .activity-page-two {
    .img-container {
      width: 25rem !important;
      height: 25rem !important;
      .screenTwo {
        width: 15rem !important;
        padding-top: 10rem !important;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .banner-wrapper {
    .banner-section {
      max-width: 100% !important;
      .banner-img-block {
        gap: 8px !important;
        .img-grp {
          gap: 8px;
        }
        .img-grp-2 {
          gap: 8px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .activity-page-5 {
    .img-block {
      img {
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .activity-page-two {
    .left-block {
      .img-container {
        display: none !important;
      }
      .screenTwo-phone-size {
        display: block !important;
        width: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .activity-page-section {
    .content-block-3 {
      .content-section {
        .DownloadText-img {
          height: 7rem !important;
        }
        .os-img img {
          height: 19rem !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 200px) and (max-width: 480px) {
  .header-section {
    .searchBar {
      padding-left: 10px !important;
      padding-right: 10px !important;
      .left-section {
        gap: 3px !important;
      }
      .nav-menu-btn {
        padding: 10px 10px 10px 20px;
      }
    }
  }

  .activity-page-one {
    .discription-block {
      div {
        width: 100% !important;
      }
    }
    .left-block {
      .activity-near-you {
        img {
          width: 100%;
        }
      }
    }
  }

  .activity-page-two {
    .discription-block {
      div {
        width: 100% !important;
      }
    }
  }

  .activity-page-section {
    .content-block-3 {
      .content-section {
        padding: 30px;
        .DownloadText-img {
          height: 5rem !important;
        }
        .os-img img {
          height: 9rem !important;
        }
      }
    }
  }

  .activity-page-5 {
    .mob-screen-layout {
      img {
        width: 100% !important;
      }
    }
  }

  .footer {
    padding: 45px !important;
    .footer-section {
      justify-content: center !important;
    }
    .footer-section-2 {
      font-size: 12px !important;
      justify-content: center !important;
    }
    .footer-block-2 {
      justify-content: center !important;
      flex-wrap: wrap !important;
      align-content: center !important;
    }
  }
}
/* General body styling */
.body {
  font-family: 'Arial', sans-serif;
  line-height: 1.8; 
  color: #333; 
  margin: 20px;
}

/* Main FAQ title */
.h1 {
  font-size: 28px;
  color: #2F4858;
  font-weight: bold;
  margin-bottom: 30px; 
}

/* Subsection headers like "General" */
.h2 {
  font-size: 22px;
  color: #2F4858; 
  margin-bottom: 20px; 
  font-weight: bold;
}

/* Styling for individual questions */
.question {
  font-weight: bold; 
  font-size: 18px;
  margin-top: 15px; 
  color: #333; 
}

/* Styling for answers */
.answer {
  font-size: 16px; 
  margin-bottom: 25px; 
  color: #555;
  line-height: 1.8; 
}
.link {
  color: #fff; /* Set link color to white */
  text-decoration: none; /* Remove underline */
}

.custom-image-bullets {
  margin-bottom: 10px; /* Add space between list items */
  padding-left: 20px; 
}
.footer-link {
  text-decoration: none;
  color: #ffff;
  transition: text-decoration 0.3s ease; /* Smooth transition for hover effect */
}

.footer-link:hover {
  text-decoration: underline;
}
.bgcolor {
  display: flex;           
  justify-content: center;   
  align-items: center;       
  height: 100vh;        
  background-color: black;   
  color: white;            
  font-size: 36px;            
  text-align: center;  
}
.img-custom-size {
  width: 200px;
  height: auto;
}


.custom-text {
  color: #ffffff;
  text-align: center;
}

