* {
  padding: 0;
  margin: 0;
  font-family: Inter;
}

html::-webkit-scrollbar {
  width: 20px;
}

html::-webkit-scrollbar-track {
  background-color: aliceblue;
}

html::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
